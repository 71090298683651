const storageIsUnavailable = (): boolean => {
  // NOTE: document.domain === '' works for chrome, brave, firefox, and safari.
  // Test NOTE: document.domain defaults to undefined in the test environment
  //
  // If the domain is empty it means:
  // 1. We are in an iframe
  // 2. The iframe has the sandbox attribute
  // 3. The sandbox attribute contains 'allow-scripts'
  // 4. The sandbox attribute does not contain 'allow-same-origin'.
  // 5. localStorage, sessionStorage, and indexDB are not available.
  if (document.domain === '') {
    return true;
  }
  // If the domain check fails, we still could have issues with localStorage.
  // Test localStorage and sessionStorage to make sure storage is available.
  //
  // NOTE: if we are sandboxed and we don't have 'allow-same-origin', this will
  // log to console even when wrapped with a try / catch block.
  const test = '__storage_test__';
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    window.sessionStorage.setItem(test, test);
    window.sessionStorage.removeItem(test);
    return false;
  } catch (e) {
    return true;
  }
};
export default storageIsUnavailable;
